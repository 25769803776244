import { useEffect, useState } from "react"
import { useAuth } from "react-oidc-context"

import { WorkspaceProps } from "../Index"
import { DbAgentInterface, DbAgentModel as DbAgentModelInterface, DbRights } from "../../../config/Db"
import { getAgent, getModels, insertAgentWithModel, updateAgent } from "../../../services/Agent"
import { useDb } from "../../../services/Db"
import { PageTitle } from "../../../components/Elements/Title"
import { UserHasRights } from "../../../services/Workspace"
import { Button, Label, Select, Spinner, Textarea, TextInput } from "flowbite-react"
import { ArrowLeftIcon, CheckIcon, UserPlusIcon } from "@heroicons/react/24/outline"
import { useTranslation } from "react-i18next"
import { ErrorMessage } from "@hookform/error-message"
import { useForm } from "react-hook-form"
import { PaginatedTable } from "../../../components/Elements/PaginatedTable"


export const WorkspaceAgents = (props: WorkspaceProps) => {
    const pagesize = 10

    const auth = useAuth()
    const db = useDb("app", auth.user?.access_token)
    const { t } = useTranslation()
    const { register, setValue, handleSubmit, formState: { errors, isDirty, isValid, isSubmitting } } = useForm({ mode: "onSubmit" });
    const [models, setModels] = useState<DbAgentModelInterface[]>([])
    const [currentAgent, setCurrentAgent] = useState<DbAgentInterface | undefined>()

    const newAgentHandler = () => {
        if (models.length > 0 && props.workspaceConfig) {
            insertAgentWithModel(db, props.workspaceConfig.workspace, t('settings.agents.defaultname'), models[0]).then((agt: DbAgentInterface) => {
                setCurrentAgent(agt)
            }).catch(() => {
                // TODO add toast
            })
        }
    }

    const agentSubmit = (agt: DbAgentInterface | undefined, data: any) => {
        if (agt) {
            agt.name = data.name
            agt.model_id = data.model_id
            agt.system_prompt = data.prompt
            updateAgent(db, agt).then(() => {
                setCurrentAgent(undefined)
            }).catch(() => { })
        }
    }

    const AgentSelectHandler = (id: number) => {
        if (props.workspaceConfig && db && auth.user) {
            getAgent(db, props.workspaceConfig.workspace, id).then((agt: DbAgentInterface) => {
                setCurrentAgent(agt)
            })
        }
    }

    useEffect(() => {
        if (currentAgent) {
            setValue("name", currentAgent.name)
            setValue("model_id", currentAgent.agent_model?.id || models[0].id)
            setValue("prompt", currentAgent.system_prompt)
        }
    }, [currentAgent])

    useEffect(() => {
        if (auth.user && props.workspaceConfig) {
            getModels(db).then((values: DbAgentModelInterface[]) => {
                setModels(values)
            }).catch((error) => {
                console.error(error)
            })
        }
    }, [auth.user, props.workspaceConfig])

    return (
        <div className="flex flex-col gap-4">
            <PageTitle
                subtitle={t('settings.agents.subtitle')}
                rightbutton={UserHasRights(props.workspaceConfig?.role, [DbRights.AppWorkspacePromptReadWrite]) ?
                    <Button color='action' onClick={newAgentHandler}>
                        <span>{t('settings.agents.new')}</span>
                        <UserPlusIcon className='ml-2 h-5 w-5' />
                    </Button>
                    :
                    <></>}
            >
                {t('settings.agents.title')}
            </PageTitle >


            {
                currentAgent ?
                    <div>
                        <form onSubmit={handleSubmit((data: any) => agentSubmit(currentAgent, data))} className="flex flex-col gap-4">
                            <div className="w-full flex flew-row flex-wrap items-start gap-4">
                                <div className="w-64">
                                    <Label htmlFor='name' value={t('settings.agents.form.name')} />
                                    <TextInput type="text" {...register("name", {
                                        required: t('general.required', { field: t('settings.agents.form.name') })
                                    })}></TextInput>
                                    <ErrorMessage as={<span className='text-xs font-mono text-red-700 dark:text-red-500' />} errors={errors} name="email" />
                                </div>
                                <div>
                                    <Label htmlFor='agent' value={t('settings.agents.form.model')} />
                                    <Select className="mt-1" {...register("model_id")} defaultValue={currentAgent?.agent_model?.name}>
                                        {
                                            models.map((m: DbAgentModelInterface) => (
                                                <option key={m.id} value={m.id}>{m.provider} / {m.name}</option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <Label htmlFor='prompt' value={t('settings.agents.form.prompt')} />
                                <Textarea rows={10} {...register("prompt")}></Textarea>
                            </div>
                            <div className="flex w-full justify-between">
                                <Button className="w-fit" color="transparent" onClick={() => setCurrentAgent(undefined)}>
                                    <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                    {t('settings.agents.form.cancelbutton')}
                                </Button>
                                <Button className="w-fit" color="submit" type="submit" disabled={!isDirty || !isValid}>
                                    {t('settings.agents.form.button')}
                                    {isSubmitting ? <Spinner className="ml-2" color="info" aria-label="Submitting spinner" />
                                        : <CheckIcon className="ml-2 h-5 w-5" />}
                                </Button>

                            </div>
                        </form>
                    </div >
                    : <PaginatedTable
                        item={t('settings.agents.item')}
                        table='workspace_agent'
                        fields={['name', 'created_at', 'agent_model(identifier)']}
                        eq={{ workspace_id: props.workspaceConfig?.workspace.id || 0 }}
                        order={{ updated_at: false }}
                        pageSize={pagesize}
                        onClick={AgentSelectHandler}
                        headers={[
                            {
                                label: 'name',
                                path: 'name',
                                format: 'text',
                            },
                            {
                                label: 'model',
                                path: 'agent_model.identifier',
                                format: 'text',
                                badge: 'blue'
                            },
                            {
                                label: 'created at',
                                path: 'created_at',
                                format: 'date'
                            }
                        ]}></PaginatedTable>
            }
        </div >
    )
}