import colors from 'tailwindcss/colors'

const avatarColors = [
    colors.red, colors.orange, colors.amber, colors.yellow, colors.lime, colors.green,
    colors.emerald, colors.teal, colors.cyan, colors.sky, colors.blue, colors.indigo,
    colors.fuchsia, colors.pink, colors.rose
]

export const getGordonAvatar = (size: number) => {
    const letters = 'Go'
    const color = colors.purple
    const canvas = document.createElement('canvas');
    canvas.width = size
    canvas.height = size
    const context = canvas.getContext("2d");
    if (context) {
        const grd = context.createRadialGradient(0, 0, 5, size, size, 2 * size)
        grd.addColorStop(0, color['600'])
        grd.addColorStop(1, color['900'])
        context.fillStyle = grd
        context.fillRect(0, 0, canvas.width, canvas.height)
        context.font = Math.round(canvas.width / 2) + "px Arial"
        context.textAlign = "center"
        context.fillStyle = "white"
        context.fillText(letters, size / 2, size / 1.5)
        return canvas.toDataURL()
    }
    return ''

}


export const getAvatarImage = (first_name: string | undefined, last_name: string | undefined, size: number): string => {
    if (!first_name || !last_name) return ''

    const letters = `${first_name[0]}${last_name[0]}`
    const color = avatarColors[(first_name.charCodeAt(0) + last_name.charCodeAt(0)) % 15]

    const canvas = document.createElement('canvas');
    canvas.width = size
    canvas.height = size
    const context = canvas.getContext("2d");
    if (context) {
        const grd = context.createRadialGradient(0, 0, 5, size, size, 2 * size)
        grd.addColorStop(0, color['600'])
        grd.addColorStop(1, color['900'])
        context.fillStyle = grd
        context.fillRect(0, 0, canvas.width, canvas.height)
        context.font = Math.round(canvas.width / 2) + "px Arial"
        context.textAlign = "center"
        context.fillStyle = "white"
        context.fillText(letters, size / 2, size / 1.5)
        return canvas.toDataURL()
    }
    return ''
}
