

export const DbRights: { [key: string]: string } = {
    WorkspaceReadWrite: 'WorkspaceReadWrite',
    SettingsHomeRead: 'SettingsHomeRead',
    SettingsHomeReadWrite: 'SettingsHomeReadWrite',
    SettingsInviteRead: 'SettingsInviteRead',
    SettingsInviteReadWrite: 'SettingsInviteReadWrite',
    SettingsMemberRead: 'SettingsMemberRead',
    SettingsMemberReadWrite: 'SettingsMemberReadWrite',
    SettingsAuditLogRead: 'SettingsAuditLogRead',
    SettingsChatRead: 'SettingsChatRead',
    SettingsChatReadWrite: 'SettingsChatReadWrite',
    SettingsBillingRead: 'SettingsBillingRead',
    SettingsBillingReadWrite: 'SettingsBillingReadWrite',
    SettingsApiKeyRead: 'SettingsApiKeyRead',
    SettingsApiKeyReadWrite: 'SettingsApiKeyReadWrite',
    SettingsRightsRead: 'SettingsRightsRead',
    SettingsRightsReadWrite: 'SettingsRightsReadWrite',

    AppTranscriptRead: 'AppTranscriptRead',
    AppTranscriptReadWrite: 'AppTranscriptReadWrite',

    AppWorkspacePromptRead: 'AppWorkspacePromptRead',
    AppWorkspacePromptReadWrite: 'AppWorkspacePromptReadWrite'
}

export const DbWorkspaceOwner = 'Owner'

export interface DbUserInterface {
    id?: number
    ref?: string
    username?: string
    email?: string
    first_name?: string
    last_name?: string
    created_at?: string
    updated_at?: string
}

export interface DbWorkspaceInterface {
    id?: number
    ref: string
    name: string
}

export interface DbRightInterface {
    id?: number
    name: string
}

export interface DbRoleInterface {
    id?: number
    name: string
    description?: string
    workspace?: DbWorkspaceInterface
    role_right: Array<{ right: DbRightInterface }>
}

export interface DbUserWorkspaceInterface {
    id?: number
    ref: string
    role: DbRoleInterface
    user: DbUserInterface
    workspace: DbWorkspaceInterface
    created_at?: string
    updated_at?: string
}

export interface DbInviteInterface {
    id?: number
    ref: string
    status: string
    to_user_email: string
    role: DbRoleInterface
    user?: DbUserInterface
    created_at?: string
    updated_at?: string
    since?: string
    workspace?: DbWorkspaceInterface
}

export interface DbFileInterface {
    id?: number
    ref: string
    workspace_id: number
    key: string
    name: string
    size?: number
    created_at?: string
    updated_at?: string
}

export interface DbContextMessageInterface {
    id?: number
    ref?: string
    context_id?: number
    role: 'system' | 'user' | 'human' | 'ai' | 'assistant' | 'robot'
    content: string
    end?: boolean
    hidden?: boolean
}

export interface DbAgentModel {
    id?: number
    ref?: string
    provider?: string
    name?: string
    identifier?: string
}

export interface DbAgentInterface {
    id?: number
    ref?: string
    workspace_id?: number
    name?: string
    system_prompt?: string
    model_id?: number
    agent_model?: DbAgentModel
    created_at?: string
    updated_at?: string
}

export interface DbContextInterface {
    id?: number
    ref?: string
    workspace_id?: number
    workspace_agent_id?: number
    workspace_agent?: DbAgentInterface
    name?: string
    transcript?: string
    created_at?: string
    updated_at?: string
    context_message?: Array<DbContextMessageInterface>
}
