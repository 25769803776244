import { Fragment, useEffect, useRef, useState } from "react";
import { WorkspaceProps } from "../Index";
import { PageTitle } from "../../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import { Badge, Button, Dropdown, Spinner } from "flowbite-react";
import { FileDrop } from "../../../components/Elements/FileDrop";
import { audioBufferToWav } from "../../../services/Audio";
import { ChevronDownIcon, PaperAirplaneIcon, TrashIcon } from "@heroicons/react/24/outline";
import { httpApiPost } from "../../../services/HttpApi";
import { useAuth } from "react-oidc-context";
import { DbAgentInterface, DbContextInterface, DbContextMessageInterface, DbRights } from "../../../config/Db";
import { deleteContextMessage, getContext, insertContextMessage, updateContext } from "../../../services/Context";
import { useDb } from "../../../services/Db";
import { NameEditable } from "../../../components/Elements/NameEditable";
import Moment from "react-moment";
import { useToast } from "../../../components/Contexts/ToastContext";
import { UserHasRights } from "../../../services/Workspace";
import { ChatCard } from "../../../components/Elements/Chatcard";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getAgents } from "../../../services/Agent";

export interface TranscriptAssistantProps extends WorkspaceProps {
    contextRef: string | undefined
    updateContextTitle: (identifier: number, value: string) => void
    removeCurrentContext: () => void
}


interface chatMessage {
    role: 'system' | 'user' | 'assistant' | 'human' | 'robot',
    content: string
    end?: boolean
}

const getMediaLink = (src: Blob | ArrayBuffer | string, type: string): string => {
    if (src instanceof Blob) {
        return URL.createObjectURL(src)
    } else {
        const blob = new Blob([src], { type: type })
        return URL.createObjectURL(blob)
    }
}

const downloadLink = (url: string, name: string,) => {
    const a = document.createElement('a')
    a.href = url
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export function ApplicationAssistant(props: TranscriptAssistantProps) {

    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const { t, i18n } = useTranslation()
    const toast = useToast()


    const [context, setContext] = useState<DbContextInterface | undefined>(undefined)
    const [agents, setAgents] = useState<DbAgentInterface[]>([])
    const [currentAgent, setCurrentAgent] = useState<DbAgentInterface | undefined>()

    const [fileDropMessage, setFileDropMessage] = useState<string>('application.transcript.filedrop')

    /**
     * Chat variables
     */
    const [chatMessages, setChatMessages] = useState<DbContextMessageInterface[]>([])
    const [lastMessage, setLastMessage] = useState<chatMessage | undefined>(undefined)
    const [waitForChat, setWaitForChat] = useState<boolean>(false)

    //const messageInputRef = useRef<HTMLInputElement>(null)
    const messagesEndRef = useRef<HTMLDivElement>(null)
    const { sendJsonMessage, readyState } = useWebSocket(
        `${import.meta.env.VITE_WS_URL}/workspaces/${props.workspace_ref}/chat`,
        {
            share: false,
            reconnectAttempts: 360,
            reconnectInterval: 10,
            shouldReconnect: () => true,
            onClose: () => { },
            onMessage: (event: WebSocketEventMap["message"]) => {
                const msg: chatMessage = JSON.parse(event.data)
                setWaitForChat(false)
                if (msg.end == true && context) {
                    insertContextMessage(db, context.id || 0, 'assistant', msg.content).then((newmsg: DbContextMessageInterface) => {
                        setChatMessages(prev => { return [...prev, newmsg] })
                        setLastMessage(undefined)
                        scrollToBottom()
                    })
                } else {
                    setLastMessage(msg)
                    scrollToBottom()
                }
            },
        }
    )

    /**
     * Common methods
     */

    const haveConversation = (): boolean => chatMessages.filter((v) => v.role != 'system').length > 0

    const updateContextTitle = (id: number, title: string) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            setContext(prev => {
                if (prev) {
                    return {
                        ...prev,
                        name: title
                    }
                }
            })
            props.updateContextTitle(id, title)
        }
    }

    const updateContextAgent = (agt: DbAgentInterface) => {
        if (context && context.id) {
            context.workspace_agent_id = agt.id
            updateContext(db, context.id, context).then((value: DbContextInterface) => {
                getContext(db, props.workspaceConfig.workspace?.id || 0, value.ref || '').then((ctx) => {
                    if (ctx) {
                        setContext(ctx)
                        setCurrentAgent(ctx?.workspace_agent)
                        reloadMessages(ctx)
                    }
                })
            })
        }
    }

    const handleInput = (file: File): Promise<void> => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            if (file.type.startsWith('audio/') || file.type.startsWith('video/')) {
                return readMedia(file)
            } else if (file.type.startsWith('text/')) {
                return extractText(file)
            }
        }
        return Promise.reject()
    }

    const readMedia = (file: File): Promise<void> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const arrBuffer: ArrayBuffer = event.target?.result as ArrayBuffer;
                createAudio(arrBuffer).then(() => {
                    return resolve()
                })
            };
            reader.readAsArrayBuffer(file);
        })
    }

    const extractText = (file: File): Promise<void> => {
        return new Promise((resolve, _) => {
            setFileDropMessage('application.transcript.extracttext')
            const reader = new FileReader();
            reader.onload = function (event) {
                if (context && context.id) {
                    startConversation(event.target?.result as string || '')
                }
                return resolve()
            }
            reader.readAsText(file);
        })
    }

    const createAudio = (arrBuffer: ArrayBuffer): Promise<void> => {
        return new Promise((resolve, _) => {
            setFileDropMessage('application.transcript.createaudio')
            const audioCtx = new AudioContext({ sampleRate: 8000 });
            audioCtx.decodeAudioData(arrBuffer, function (audioBuffer: AudioBuffer) {
                const buffer = audioBufferToWav(audioBuffer, false, true)
                setFileDropMessage('application.transcript.starttranscription')
                httpApiPost(`/workspaces/${props.workspaceConfig.workspace.ref}/transcript`, buffer, auth.user?.access_token || '')
                    .then((value) => {
                        if (context && context.id) {
                            startConversation(value.text)
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
            });
        })
    }

    /**
     * Chat phase methods
     */
    const sendMessage = (msg: string) => {
        if (context && context.id && msg.trim().length > 0 && props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            setWaitForChat(true)
            insertContextMessage(db, context.id, 'user', msg).then((newmsg: DbContextMessageInterface) => {
                setChatMessages(prev => {
                    const n = [...prev, newmsg]
                    sendJsonMessage({
                        auth: { token: auth.user?.access_token },
                        provider: context.workspace_agent?.agent_model?.provider,
                        model: context.workspace_agent?.agent_model?.name,
                        messages: n.map((v: DbContextMessageInterface) => { return { role: v.role, content: v.content } })
                    })
                    return n
                })
            }).catch(() => {
                toast.fire('failure', t('application.transcript.chat.error'), 3000)
            })
        }
    }

    const startConversation = (transcript: string) => {
        if (context && context.id && context.workspace_agent) {
            const ctxid = context.id
            setWaitForChat(true)
            insertContextMessage(db, ctxid, 'user', transcript).then((newmsg: DbContextMessageInterface) => {
                const initial: DbContextMessageInterface[] = [
                    {
                        role: 'system',
                        content: context.workspace_agent?.system_prompt || '',
                        hidden: false
                    },
                    newmsg
                ]
                setChatMessages(initial)
                sendJsonMessage({
                    auth: { token: auth.user?.access_token },
                    provider: context.workspace_agent?.agent_model?.provider,
                    model: context.workspace_agent?.agent_model?.name,
                    messages: initial.map((v: DbContextMessageInterface) => { return { role: v.role, content: v.content } })
                })
            }).catch(() => {
                toast.fire('failure', t('application.transcript.chat.error'), 3000)
            })
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
    }

    const reloadMessages = (ctx: DbContextInterface) => {
        const messages = ctx?.context_message || []
        setChatMessages([{
            role: 'system',
            content: ctx?.workspace_agent?.system_prompt || '',
            hidden: false
        }, ...messages])
    }


    const reloadContext = () => {
        if (props.workspaceConfig?.workspace && props.workspaceConfig.workspace.id && props.contextRef && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            getContext(db, props.workspaceConfig.workspace.id, props.contextRef).then((value: DbContextInterface | undefined) => {
                setContext(value)
                if (value?.workspace_agent) {
                    setCurrentAgent(value.workspace_agent)
                    reloadMessages(value)
                }
            })
        } else {
            setContext(undefined)
        }
    }


    /**
     * UseEffects
     */
    // On contextRef of Workspace changes
    useEffect(() => {
        reloadContext()
    }, [props.workspaceConfig?.workspace, props.contextRef])

    // On chatmessage change, scroll to Bottom
    useEffect(() => {
        if (chatMessages.length > 0) {
            scrollToBottom()
        }
    }, [chatMessages])

    useEffect(() => {
        if (props.workspaceConfig && db && auth.user) {
            getAgents(db, props.workspaceConfig.workspace).then((values: DbAgentInterface[]) => {
                setAgents(values)
            }).catch(() => setAgents([]))
        }
    }, [props.workspaceConfig, auth.user])


    return (
        <>
            {
                props.workspaceConfig?.role && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptRead, DbRights.AppTranscriptReadWrite]) && context ?
                    <>
                        {/* Title */}
                        <PageTitle
                            margin='mb-4'
                            rightbutton={
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="w-80">
                                        {
                                            currentAgent && haveConversation() ?
                                                <></> :
                                                <Dropdown
                                                    hidden={true}
                                                    label=""
                                                    renderTrigger={
                                                        () =>
                                                            <div className='w-full font-bold text-gray-900 dark:text-gray-200 truncate cursor-pointer'>
                                                                <div className="w-full flex flex-row justify-between">
                                                                    <div className="flex flex-row gap-2 items-center">
                                                                        {currentAgent?.name}
                                                                        <Badge size='xs'>{currentAgent?.agent_model?.identifier}</Badge>
                                                                    </div>
                                                                    <div>
                                                                        <ChevronDownIcon className='ml-2 h-4 w-4 inline'></ChevronDownIcon>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                >
                                                    {
                                                        agents.map((agt: DbAgentInterface) => (
                                                            <div key={agt.ref}>
                                                                <Dropdown.Item onClick={() => updateContextAgent(agt)}>
                                                                    <div className="flex flex-row gap-2 items-center">
                                                                        {agt?.name} ({agt?.agent_model?.identifier})
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </div>

                                                        ))
                                                    }
                                                </Dropdown>
                                        }

                                    </div>
                                    <Button aria-label='Remove current assistant' color="transparent" onClick={() => { props.removeCurrentContext() }}>
                                        <TrashIcon className="text-red-800 dark:text-red-600 h-5 w-5" />
                                    </Button>
                                </div>

                            }
                            subtitle={
                                <>
                                    <span>{t('application.transcript.context.created')} <Moment locale={i18n.language} fromNow>{context.created_at}</Moment></span>
                                    {
                                        currentAgent ? <><span> {t('application.transcript.context.withagent')} </span><span className="font-bold">{currentAgent?.name}</span> ({currentAgent.agent_model?.identifier})</> : <></>
                                    }
                                </>
                            }
                        >
                            <NameEditable className='p-1' name={context.name || ''} identifier={context.id} save={updateContextTitle} maxchars={52} />
                        </PageTitle>

                        <div className="flex flex-col h-full items-center overflow-y-scroll">
                            {
                                chatMessages.map((value: DbContextMessageInterface, index: number) => (
                                    <Fragment key={index}>
                                        {
                                            <ChatCard
                                                key={value.id}
                                                role={value.role}
                                                user={auth.user?.profile}
                                                remove={() => {
                                                    deleteContextMessage(db, context.id || 0, value?.id || 0).then(() => {
                                                        setChatMessages(prev => {
                                                            return prev.filter((m: DbContextMessageInterface) => { return m.id != value.id })
                                                        })
                                                    })
                                                }}
                                                download={() => {
                                                    downloadLink(getMediaLink(value.content, 'text/markdown'), `${context.name}.md`)
                                                }}
                                            >{value.content}</ChatCard>
                                        }
                                    </Fragment>
                                ))
                            }
                            {
                                waitForChat ? <Spinner className="h-8 w-8"></Spinner> : <></>
                            }
                            {
                                !haveConversation() ?
                                    <div className="w-full mb-4 flex flex-row gap-2 flex-wrap items-stretch">
                                        <div className="w-full h-full">
                                            <FileDrop width="w-full h-full" text={fileDropMessage} fileHandler={handleInput}></FileDrop>
                                        </div>
                                    </div> : <></>
                            }

                            {
                                lastMessage ? <ChatCard role={lastMessage.role} user={auth.user?.profile}>{lastMessage.content}</ChatCard> : <></>
                            }
                            {
                                haveConversation() ? <>
                                    <div ref={messagesEndRef} className='w-full min-h-[116px]'></div>
                                    <div className="fixed bottom-0 flex flex-col justify-end items-center bottom-4 w-full bg-transparent">
                                        <div className="bg-transparent dark:text-gray-100 text-xs text-gray-500 w-2/3">{
                                            readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite]) ?
                                                t('application.transcript.chat.submit') :
                                                t('application.transcript.wait')
                                        }</div>
                                        <NameEditable
                                            className='bg-white dark:bg-gray-700 mb-2 mx-4 shadow-lg w-2/3 px-2 py-3 rounded rounded-lg border border-primary-700 max-h-80 min-h-20 overflow-y-scroll'
                                            name=""
                                            editable={readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite])}
                                            needSubmit={(event) => { return (event.ctrlKey && event.key == 'Enter') || (event.metaKey && event.key == 'Enter') }}
                                            save={(_: number, value: string) => { sendMessage(value) }}
                                            icon={<PaperAirplaneIcon className="h-6 w-6"></PaperAirplaneIcon>}
                                        />
                                    </div>
                                </> : <></>

                            }
                        </div>
                    </>
                    : <></>
            }
        </>
    )
}