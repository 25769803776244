import { ReactElement, useEffect, useRef } from "react"

export interface NameEditableProps {
    name: string
    identifier?: number
    save?: (identifier: number, value: string) => void
    needSubmit?: (event: any) => boolean
    editable?: boolean
    className?: string,
    maxchars?: number,
    icon?: ReactElement
}

export const NameEditable = (props: NameEditableProps) => {

    const nameRef = useRef<HTMLDivElement | null>(null);
    const save = () => {
        const text = nameRef.current?.innerText || ''
        if (props.save != undefined) {
            props.save(props.identifier || 0, text)
        }
    }

    const handleEditable = () => {
        nameRef.current?.focus()
    }

    useEffect(() => {
        if (nameRef && nameRef.current && nameRef.current.textContent !== props.name) {
            nameRef.current.textContent = props.name
        }
    });

    return (
        <>
            <div className={`${props.className} outline-primary-900 ring-0 ${props.editable === false ? 'bg-gray-100' : 'bg-transparent'}`} ref={nameRef} contentEditable={props.editable !== false} onBlur={() => { if (!props.needSubmit) save() }} onClick={handleEditable} onKeyDown={(event) => {
                if ((!props.needSubmit && event.key === 'Enter') || (props.needSubmit && props.needSubmit(event))) {
                    event.preventDefault()
                    if (props.needSubmit) {
                        save()
                    } else {
                        nameRef.current?.blur()
                    }
                    return
                }
                if (props.maxchars && event.key !== 'Backspace' && event.key !== 'Delete' && nameRef.current && nameRef.current.textContent && nameRef.current.textContent.length > props.maxchars) {
                    nameRef.current.textContent = nameRef.current.textContent.slice(0, props.maxchars + 1)
                    event.preventDefault()
                }
            }}>
            </div>
            <div className="absolute bottom-3 left-[84%] text-primary-700 dark:text-primary-200 cursor-pointer" onClick={() => save()}>{props.icon}</div>
        </>
    )
}
