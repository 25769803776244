import { Avatar, Card } from 'flowbite-react'
import { getAvatarImage, getGordonAvatar } from './AvatarImage'
import { ArrowDownTrayIcon, ClipboardIcon, MinusCircleIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { MarkdownRenderer } from './Markdown'
import { UserProfile } from 'oidc-client-ts'
import { useToast } from '../Contexts/ToastContext'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import { useIsOverflow } from '../Contexts/isOverflow'

export interface ChatCardInterface {
    role: 'system' | 'user' | 'human' | 'ai' | 'assistant' | 'robot',
    children: string
    className?: string
    user?: UserProfile
    remove?: () => void,
    download?: () => void
}

export const ChatCard = (props: ChatCardInterface) => {
    const toast = useToast()
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState<boolean>(false)
    const [needExpand, setNeedExpand] = useState<boolean>(false)
    const contentRef = useRef(null)
    useIsOverflow(contentRef, (overflow: boolean) => {
        setNeedExpand(overflow)
    });



    const copyToClipboard = (content: string) => {
        navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                /* write to the clipboard now */
                navigator.clipboard.writeText(content)
                toast.fire('success', 'Content successfuly copied to clipboard', 1000)
            }
            else {
                toast.fire('failure', 'Cannot copy content to clipboard, please check you navigator permisions', 3000)
            }
        });
    }

    return (
        <>
            <Card className={`w-full my-2 border text-gray-900 dark:text-gray-200 border-gray-300 bg-white dark:border-white dark:bg-gray-800 ${props.className} gap-0`}>

                <div className='flex flex-row items-start justify-begin'>
                    {
                        props.role === 'user' || props.role === 'human' ?
                            <Avatar className='mr-2 ml-2 h-8 basis-8 grow-0 shrink-0' size={'sm'} alt="" img={getAvatarImage(props.user?.given_name, props.user?.family_name, 64)} rounded />
                            : props.role === 'assistant' || props.role === 'ai' || props.role === 'robot' ?
                                < Avatar className='mr-2 ml-2 h-8 basis-8 grow-0 shrink-0' size={'sm'} alt="" img={getGordonAvatar(64)} rounded /> : <></>
                    }
                    {
                        <div className={`flex-grow text-sm overflow-x-scroll border-0 rounded-md border ${props.role === 'user' || props.role === 'human' || props.role === 'system' ? 'font-sans' : 'font-mono'}`}>
                            {props.role === 'system' ? <div className='font-bold mb-1'>{t('application.transcript.prompts.system')}</div> : <></>}
                            {
                                expanded ?
                                    <div className='h-fit overflow-y-scroll' ref={contentRef}>
                                        <MarkdownRenderer>{props.children}</MarkdownRenderer>
                                    </div> :
                                    <div className='max-h-64 overflow-y-scroll' ref={contentRef}>
                                        <MarkdownRenderer>{props.children}</MarkdownRenderer>
                                    </div>
                            }
                        </div>
                    }
                </div>
                <div className='flex flex-row items-center justify-between'>
                    {
                        needExpand && !expanded ?
                            <div><PlusCircleIcon onClick={() => { setExpanded(true) }} className='h-4 w-4'></PlusCircleIcon></div>
                            : <></>

                    }
                    {
                        !needExpand && expanded ?
                            <div><MinusCircleIcon onClick={() => { setExpanded(false) }} className='h-4 w-4'></MinusCircleIcon></div>
                            : <div></div>
                    }
                    <div>
                        {
                            props.role !== 'system' ?
                                <div className='mr-2 ml-2 basis-16 grow-0 shrink-0 items-start'>
                                    <div className='flex flex-row gap-4'>
                                        {
                                            props.remove ?
                                                <TrashIcon className='h-4 w-4 text-red-500 hover:text-red-400 dark:text-red-500 dark:hover:text-red-400 cursor-pointer' onClick={props.remove}></TrashIcon>
                                                : <></>
                                        }
                                        {
                                            props.download ?
                                                <ClipboardIcon className='h-4 w-4 text-gray-500 hover:text-gray-400 dark:text-gray-200 hover:dark:text-gray-100 cursor-pointer' onClick={() => { copyToClipboard(props.children.replace(/\n$/, '')) }}></ClipboardIcon>
                                                : <></>
                                        }
                                        {
                                            props.download ?
                                                <ArrowDownTrayIcon className='h-4 w-4 text-gray-500 hover:text-gray-400 dark:text-gray-200 hover:dark:text-gray-100 cursor-pointer' onClick={props.download}></ArrowDownTrayIcon>
                                                : <></>

                                        }
                                    </div>
                                </div> : <></>
                        }

                    </div>
                </div>
            </Card>
        </>
    )
}