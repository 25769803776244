import type { CustomFlowbiteTheme } from 'flowbite-react';

const templateTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      submit: 'bg-secondary-700 hover:bg-secondary-900 dark:bg-secondary-700 dark:hover:bg-secondary-900 text-white border-transparent border focus:ring-secondary-300 black:focus:ring-secondary-300',
      action: 'bg-primary-900 hover:bg-primary-700 text-white border-transparent border dark:bg-primary-700 dark:hover:bg-primary-600',
      transparent: 'bg-transparent border-transparent text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700',
      emergency: 'text-white bg-red-700 border border-red-700 enabled:hover:bg-red-800 enabled:hover:border-red-800  focus:ring-red-500 dark:bg-red-700 dark:text-white dark:border-red-700 dark:enabled:hover:bg-red-800 dark:enabled:hover:border-red-800 dark:focus:ring-red-800',
    },
  },
  label: {
    root: {
      base: 'text-sm font-medium mb-1'
    }
  },
  textInput: {
    base: 'flex mt-1',
    field: {
      input: {
        colors: {
          'gray': 'bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-700 focus:ring-primary-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-700 dark:focus:ring-primary-700'
        },
      },
    }
  },
  select: {
    field: {
      select: {
        colors: {
          'gray': 'bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-700 focus:ring-primary-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-700 dark:focus:ring-primary-700'
        },
      },
    }
  },
  navbar: {
    root: {
      inner: {
        base: 'mx-auto flex flex-wrap items-center justify-between',
        fluid: {
          on: '',
          off: ''
        }
      }
    }
  },
  sidebar: {
    root: {
      base: 'h-full fixed inset-x-0 delay-75',
      collapsed: {
        on: 'w-16',
        off: 'phone:w-64 tablet:w-80'
      },
      inner: 'h-full overflow-y-auto overflow-x-hidden rounded bg-white px-3 pt-20 dark:bg-gray-800'
    },
  },
  card: {
    root: {
      children: "flex flex-col justify-center gap-4 p-4"
    }
  },
  checkbox: {
    root: {
      color: {
        disabled: 'text-gray-400 focus:ring-gray-600 dark:ring-offset-gray-400 dark:focus:ring-gray-600',
        default: 'text-primary-600 focus:ring-primary-600 dark:ring-offset-gray-800 dark:focus:ring-primary-600',
        red: 'text-red-600 focus:ring-red-600 dark:ring-offset-gray-800 dark:focus:ring-red-600',
        green: 'text-green-600 focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-green-600',
        orange: 'text-orange-600 focus:ring-orange-600 dark:ring-offset-gray-800 dark:focus:ring-orange-600',
        blue: 'text-blue-600 focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-blue-600',
      }
    }
  },
  badge: {
    root: {
      color: {
        failure: "bg-red-100 text-red-800 group-hover:bg-red-200 dark:bg-red-200 dark:text-red-900 dark:group-hover:bg-red-300",
        success: "bg-green-100 text-green-800 group-hover:bg-green-200 dark:bg-green-200 dark:text-green-900 dark:group-hover:bg-green-300",
        primary: "bg-primary-100 text-primary-800 group-hover:bg-primary-200 dark:bg-primary-200 dark:text-primary-900 dark:group-hover:bg-primary-300",
        secondary: "bg-secondary-100 text-secondary-800 group-hover:bg-secondary-200 dark:bg-secondary-200 dark:text-secondary-900 dark:group-hover:bg-secondary-300",
        red: "bg-red-100 text-red-800 group-hover:bg-red-200 dark:bg-red-200 dark:text-red-900 dark:group-hover:bg-red-300",
        green: "bg-green-100 text-green-800 group-hover:bg-green-200 dark:bg-green-200 dark:text-green-900 dark:group-hover:bg-green-300",
        orange: "bg-orange-100 text-orange-800 group-hover:bg-orange-200 dark:bg-orange-200 dark:text-orange-900 dark:group-hover:bg-orange-300",
        blue: "bg-blue-100 text-blue-800 group-hover:bg-blue-200 dark:bg-blue-200 dark:text-blue-900 dark:group-hover:bg-blue-300",
        yellow: "bg-yellow-100 text-yellow-800 group-hover:bg-yellow-200 dark:bg-yellow-200 dark:text-yellow-900 dark:group-hover:bg-yellow-300",
      }
    }
  },
  progress: {
    color: {
      primary: "bg-primary-800 dark:bg-primary-200",
    }
  },
  spinner: {
    color: {
      info: "fill-primary-600 dark:fill-primary-200",
    },
    size: {
      xl: "h-16 w-16"
    }
  },
  dropdown: {
    "arrowIcon": "ml-2 h-4 w-4",
    "content": "py-1 focus:outline-none",
    "floating": {
      "animation": "transition-opacity",
      "arrow": {
        "base": "absolute z-10 h-2 w-2 rotate-45",
        "style": {
          "dark": "bg-gray-900 dark:bg-gray-700",
          "light": "bg-white",
          "auto": "bg-white dark:bg-gray-700"
        },
        "placement": "-4px"
      },
      "base": "z-10 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none",
      "content": "py-1 text-sm text-gray-700 dark:text-gray-200",
      "divider": "my-1 h-px bg-gray-100 dark:bg-gray-600",
      "header": "block px-4 py-2 text-sm text-gray-700 dark:text-gray-200",
      "hidden": "invisible opacity-0",
      "item": {
        "container": "",
        "base": "flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white",
        "icon": "mr-2 h-4 w-4"
      },
      "style": {
        "dark": "bg-gray-900 text-white dark:bg-gray-700",
        "light": "border border-gray-200 bg-white text-gray-900",
        "auto": "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
      },
      "target": "w-fit"
    },
    "inlineWrapper": "flex items-center text-gray-700 dark:text-gray-200"
  }

};

export {
  templateTheme
}