import { Badge, Checkbox, Dropdown, Pagination, Table } from "flowbite-react"
import { ReactElement, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { useAuth } from "react-oidc-context"
import { remove, select, useDb } from "../../services/Db"
import { PostgrestSingleResponse } from "@supabase/postgrest-js"
import { useToast } from "../Contexts/ToastContext"
import { TrashIcon } from "@heroicons/react/24/outline"

export type PaginatedDataAction = 'delete'

export interface PaginatedTableData {
    id: number
    checked: boolean
    elements: {
        [key: string]: ReactElement
    }
}

export interface PaginatedTableProps {
    item: string
    table: string,
    fields: string[],
    eq: {
        [key: string]: string | number
    },
    order: {
        [key: string]: boolean
    },
    pageSize: number,
    onClick?: (id: number) => void,
    headers: Array<{
        label: string,
        path: string,
        format?: 'text' | 'translated' | 'date' | 'datetime',
        translate?: boolean
        badge?: 'blue' | 'green' | 'red' | 'yellow' | 'orange' | 'failure' | 'success' | 'primary' | 'secondary'
    }>
}


export const PaginatedTable = (props: PaginatedTableProps) => {
    const auth = useAuth()
    const db = useDb("app", auth.user?.access_token)
    const { t, i18n } = useTranslation()
    const toast = useToast()

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [data, setData] = useState<(PaginatedTableData)[]>([])

    const resolvePath = (data: any, path: string, defaultValue: string, format: 'text' | 'translated' | 'date' | 'datetime', badge: string | undefined): ReactElement => {
        let element: ReactElement
        switch (format) {
            case 'translated':
                element = <>{t(path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data))}</>
                break
            case 'date':
                element = <Moment locale={i18n.language} format='L'>{path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data)}</Moment>
                break
            case 'datetime':
                element = <Moment locale={i18n.language} format='L'>{path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data)}</Moment>
                break
            case 'text':
            default:
                element = <>{path.split('.').reduce((o, p) => o ? o[p] : defaultValue, data)}</>
        }
        if (badge) {
            return (<Badge className='w-fit' color={badge}>{element}</Badge>)
        } else {
            return element
        }
    }

    const refreshData = () => {
        if (!props.fields.includes('id')) {
            props.fields.push('id')
        }
        let req = select(db, props.table, props.fields.join(','))
        for (const [key, value] of Object.entries(props.eq)) {
            req = req.eq(key, value)
        }
        for (const [key, ascending] of Object.entries(props.order)) {
            req = req.order(key, { ascending: ascending })
        }
        req.range((page - 1) * props.pageSize, (page * props.pageSize) - 1)
        req.then((result: PostgrestSingleResponse<any[]>) => {
            if (result.error) {
                setTotal(0)
                setData([])
            } else {
                setTotal(result.count || 0)
                setData(result.data.map((r) => {
                    const d: PaginatedTableData = { id: r.id as number, checked: false, elements: {} }
                    props.headers.forEach((h) => {
                        d.elements[h.label] = resolvePath(r, h.path, '', h.format || 'text', h.badge)
                    })
                    return d
                }))
            }
        })
    }



    const handleChecked = (lines: PaginatedTableData[]) => {
        const ids = lines.map((v) => v.id)
        setData(prev => {
            const newData = [...prev.map((v) => {

                if (ids.includes(v.id)) {
                    v.checked = !v.checked
                }
                return v

            })]
            return newData
        })
    }

    const handleAction = (lines: PaginatedTableData[], action: PaginatedDataAction) => {
        if (action == 'delete') {
            const promises = lines.map((v) => {
                if (v.checked == true) {
                    return remove(db, props.table).eq('id', v.id)
                }
            }).filter((v) => v != undefined)
            if (promises.length > 0) {
                Promise.all(promises).then(() => {
                    toast.fire('success', t('general.delete.success', { item: props.item, total: promises.length }), 3000)
                    refreshData()
                }).catch(() => {
                    toast.fire('success', t('general.delete.failure', { item: props.item, total: promises.length }), 3000)
                    refreshData()
                })
            }

        }
    }
    <TrashIcon className="text-red-800 dark:text-red-600 h-5 w-5" />


    useEffect(() => {
        if (auth.user && db) {
            refreshData()
        }
    }, [auth.user, props.table, props.fields, props.eq, props.order, page])

    return (
        <div className="flex flex-col gap-4">
            <div className="w-fit">
                <Dropdown inline={true} label={t('general.action')}>
                    <Dropdown.Item onClick={() => handleAction(data, 'delete')}>
                        <TrashIcon className="text-red-800 dark:text-red-600 h-4 w-4 mr-2" />
                        {t('general.delete.button')}
                    </Dropdown.Item>
                </Dropdown>
            </div>
            <Table>
                <Table.Head>
                    <Table.HeadCell className="py-4 px-2"><Checkbox checked={false} onChange={() => { handleChecked(data) }}></Checkbox></Table.HeadCell>
                    {
                        props.headers.map((header, idx) => (
                            <Table.HeadCell key={idx} className="py-4 px-2">{header.label}</Table.HeadCell>
                        ))
                    }
                </Table.Head>
                <Table.Body className="divide-y">
                    {
                        data.map((line) => (
                            <Table.Row key={line.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className='cursor-pointer py-4 px-2'><Checkbox checked={line.checked} onChange={() => { handleChecked([line]) }}></Checkbox></Table.Cell>
                                {
                                    props.headers.map((h, idx) => (
                                        <Table.Cell className='cursor-pointer py-4 px-2' key={idx} onClick={() => { if (props.onClick) props.onClick(line.id) }}>
                                            {line.elements[h.label] as ReactElement}
                                        </Table.Cell>
                                    ))
                                }
                            </Table.Row>

                        ))
                    }
                </Table.Body>
            </Table>
            {
                total > 0 ?
                    <div className="flex w-full overflow-x-auto items-start sm:justify-center tablet:justify-between flex-wrap">
                        {
                            <div className="text-sm text-gray-900 dark:text-gray-200">{t('general.total', { total: total, item: props.item })}</div>
                        }
                        {
                            Math.ceil(total / props.pageSize) > 1 ?
                                <Pagination currentPage={page} totalPages={Math.ceil(total / props.pageSize)} onPageChange={setPage} />
                                : <div></div>
                        }
                    </div> : <></>
            }

        </div >

    )

}