// Postgrest client
import { PostgrestClient, PostgrestSingleResponse } from "@supabase/postgrest-js"
// Db helper
import { insert, select, update } from "./Db"
import { DbAgentInterface, DbAgentModel, DbWorkspaceInterface } from "../config/Db"



export const getModels = (db: PostgrestClient<any, string, any>): Promise<DbAgentModel[]> => {
    return new Promise((resolve, reject) => {
        select(db, 'agent_model', 'id,ref,provider,name').then((result: PostgrestSingleResponse<string[]>) => {
            if (result.error) {
                reject(result.error)
            } else {
                resolve(result.data as unknown as DbAgentModel[])
            }
        })
    })
}

export const getAgents = (db: PostgrestClient<any, string, any>, workspace: DbWorkspaceInterface): Promise<DbAgentInterface[]> => {
    return new Promise((resolve, reject) => {
        select(db, 'workspace_agent', 'id,ref,name,system_prompt,created_at,agent_model(id,provider,name,identifier)')
            .eq('workspace_id', workspace.id)
            .order('updated_at', { ascending: false })
            .then((result: PostgrestSingleResponse<Array<string>>) => {
                if (result.error) {
                    reject()
                } else {
                    resolve((result.data as unknown) as Array<DbAgentInterface>)
                }
            })
    })
}

export const insertAgentWithModel = (db: PostgrestClient<any, string, any>, workspace: DbWorkspaceInterface, name: string, model: DbAgentModel): Promise<DbAgentInterface> => {
    return new Promise((resolve, reject) => {
        insert(db, 'workspace_agent', {
            workspace_id: workspace.id,
            name: name,
            model_id: model.id
        }).then((value: PostgrestSingleResponse<Array<DbAgentInterface>>) => {
            if (value.error) {
                return reject()
            } else {
                return resolve(value.data[0])
            }
        })
    })
}


export const getAgent = (db: PostgrestClient<any, string, any>, workspace: DbWorkspaceInterface, id: number): Promise<DbAgentInterface> => {
    return new Promise((resolve, reject) => {
        select(db, 'workspace_agent', 'id,ref,name,system_prompt,created_at,agent_model(id,provider,name,identifier)')
            .eq('workspace_id', workspace.id)
            .eq('id', id)
            .maybeSingle()
            .then((result: PostgrestSingleResponse<string | null>) => {
                if (result.error || !result.data) {
                    return reject(result.error)
                } else {
                    return resolve((result.data as unknown) as DbAgentInterface)
                }
            })
    })
}

export const updateAgent = (db: PostgrestClient<any, string, any>, agent: DbAgentInterface): Promise<DbAgentInterface> => {
    return new Promise((resolve, reject) => {
        const cleaned = { ...agent }
        cleaned.id = undefined
        cleaned.agent_model = undefined
        cleaned.ref = undefined
        update(db, 'workspace_agent', cleaned).eq('id', agent.id).single().then((result: PostgrestSingleResponse<DbAgentInterface>) => {
            if (result.error) {
                return reject()
            }
            else {
                return resolve(result.data)
            }
        })
    })
}